import React, {useState} from 'react'
import Image from 'next/image'
import classNames from 'classnames'
import IconDiamondTurnRight from 'components/icons/IconDiamondTurnRight'
import IconDiamondTurnRightInverted from 'components/icons/IconDiamondTurnRightInverted'
import Link from 'next/link'
import {ButtonStoryblok} from 'types/storyblok-types'
import PlusIcon from 'components/icons/PlusIcon'
import IconArrowRotateLeft from 'components/icons/IconArrowRotateLeft'

type ButtonBlockProps = {
  onClick?: React.MouseEventHandler
  cta_url?: string
  cta_target?: string
  title: string
  icon?: string
  iconType?: ButtonIconType
  styling?: ButtonStoryblok['button_color']
  type: 'button' | 'submit'
  disabled?: boolean
  className?: string
  titleFontClassName?: string
}

enum ButtonStyle {
  primary,
  secondary,
}

/// Extend with other icons we may need to use on the button.
export enum ButtonIconType {
  iconDiamondTurnRight = 1,
  IconDiamondTurnRightInverted = 2,
  plus = 3,
  IconArrowRotateLeft = 4,
}

function ButtonBlock({
  onClick,
  cta_url,
  cta_target,
  title = 'Button',
  icon,
  iconType,
  styling,
  type,
  disabled,
  className,
  titleFontClassName,
}: ButtonBlockProps) {
  const style =
    styling && styling.length > 0
      ? styling === 'primary'
        ? ButtonStyle.primary
        : ButtonStyle.secondary
      : ButtonStyle.primary

  const extraClassNames = className ? className : ''

  const [isHovering, setIsHovering] = useState(false)

  const iconColor = () =>
    classNames('fill-dats-a1', {
      'fill-dats-s6': disabled,
      'fill-dats-s3':
        (isHovering || style === ButtonStyle.secondary) && !disabled,
    })

  const renderMatchingIcon = () => {
    if (!iconType) {
      return null
    }

    switch (iconType) {
      case ButtonIconType.iconDiamondTurnRight:
        return (
          <div className="size-5">
            <IconDiamondTurnRight className={iconColor()} />
          </div>
        )
      case ButtonIconType.IconDiamondTurnRightInverted:
        return (
          <div className="size-5">
            <IconDiamondTurnRightInverted className={iconColor()} />
          </div>
        )
      case ButtonIconType.plus:
        return <PlusIcon />

      case ButtonIconType.IconArrowRotateLeft:
        return (
          <div className="size-5">
            <IconArrowRotateLeft className={iconColor()} />
          </div>
        )

      default:
        return null
    }
  }

  const buttonStyling = classNames(
    'flex min-h-[46px] py-2 w-auto items-center justify-center space-x-[10px] px-[15px] rounded-md disabled:border disabled:bg-dats-s9 disabled:border-dats-s9 disabled:text-dats-s6',
    {
      'bg-dats-s3 text-white hover:bg-dats-s4 hover:text-dats-s3':
        style === ButtonStyle.primary,
      'border border-dats-s3 bg-white  text-dats-s3 hover:border-dats-p2 hover:bg-dats-s4':
        style === ButtonStyle.secondary,
    },
  )

  return (
    <>
      {cta_url ? (
        <Link
          href={cta_url}
          className="no-underline"
          target={cta_target ? cta_target : '_self'}
        >
          <button
            className={`${buttonStyling} ${extraClassNames}`}
            type={type}
            disabled={disabled ?? false}
            onMouseOver={() => setIsHovering(true)}
            onMouseOut={() => setIsHovering(false)}
          >
            {icon ? (
              <Image
                src={icon}
                alt=""
                width={20}
                height={20}
                unoptimized={true}
              />
            ) : null}
            {renderMatchingIcon()}

            <p
              className={`font-body text-base font-semibold ${titleFontClassName}`}
            >
              {title}
            </p>
          </button>
        </Link>
      ) : (
        <button
          className={`${buttonStyling} ${extraClassNames}`}
          type={type}
          onClick={onClick}
          disabled={disabled ?? false}
          onMouseOver={() => setIsHovering(true)}
          onMouseOut={() => setIsHovering(false)}
        >
          {icon ? (
            <Image
              src={icon}
              alt=""
              width={20}
              height={20}
              unoptimized={true}
            />
          ) : null}
          {renderMatchingIcon()}

          <p
            className={`font-body text-base font-semibold ${titleFontClassName}`}
          >
            {title}
          </p>
        </button>
      )}
    </>
  )
}

export default ButtonBlock
