export const Segment = {
  particulier: 'particulier',
  professional: 'professional',
  professionnel: 'professionnel',
}

export const FuelType = {
  tankstation: 'tankstation',
  stationservice: 'stationservice',
  laadpunt: 'laadpunt',
  pointdechargement: 'pointdechargement',
}

export const Tag = {
  individual: 'individual',
  professional: 'professional',
}
