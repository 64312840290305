import * as React from 'react'
import {SVGProps} from 'react'

const IconDiamondTurnRight = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 21 21"
    preserveAspectRatio="true"
    {...props}
  >
    <g clipPath="url(#a)">
      <path d="M9.584 1.37c.61-.61 1.598-.61 2.21 0l8.438 8.438c.61.609.61 1.597 0 2.21l-8.437 8.438c-.61.61-1.598.61-2.211 0L1.146 12.02a1.566 1.566 0 0 1 0-2.211L9.584 1.37Zm3.422 5.352a.628.628 0 0 0-.68-.137.627.627 0 0 0-.386.578v2.188h-2.5a2.502 2.502 0 0 0-2.5 2.5v1.875c0 .52.417.937.937.937s.937-.418.937-.937V11.85c0-.344.282-.625.625-.625h2.5v2.187a.625.625 0 0 0 1.067.441l3.125-3.124a.627.627 0 0 0 0-.883l-3.125-3.125Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M.69.913h20v20h-20z" />
      </clipPath>
    </defs>
  </svg>
)
export default IconDiamondTurnRight
