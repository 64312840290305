import {SbBlokData} from '@storyblok/react'
import React from 'react'

interface FallbackComponentProps {
  blok: SbBlokData
}

const FallbackComponent = ({blok}: FallbackComponentProps) => {
  return (
    <div className="flex h-auto w-full items-center justify-center rounded-md border-2 border-red-500 p-2 text-center">
      <p className="text-red-500">
        Component was not created for blok&nbsp;
        <strong>{blok.component}</strong>.
      </p>
    </div>
  )
}
export default FallbackComponent
