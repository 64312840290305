/// This function parses the embedded image resolution from a Storyblok Image Asset URL.
export function getImageResolution(filename: string) {
  const filenameComponents = filename.split('/')
  if (filenameComponents.length < 6) {
    return {width: 0, height: 0}
  }

  const resolution = filenameComponents[5]
  const resolutionComponents = resolution.split('x')
  if (resolutionComponents.length < 2) {
    return {width: 0, height: 0}
  }

  return {width: +resolutionComponents[0], height: +resolutionComponents[1]}
}
