import {MultilinkStoryblok} from 'types/storyblok-types'

export default function linkTypeChecker(linkObject?: MultilinkStoryblok) {
  if (!linkObject) {
    return null
  }

  if (linkObject.linktype == 'story' && linkObject.story) {
    if (linkObject.fuelType) {
      return `${linkObject.story?.full_slug}?fuelType=${linkObject.fuelType}`
    }
    return linkObject.story?.full_slug
  }

  if (linkObject.fuelType) {
    return `${linkObject.cached_url}?fuelType=${linkObject.fuelType}`
  }
  return linkObject.cached_url
}
