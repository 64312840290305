import {debounce} from 'lodash'

export class Analytics {
  private static instance: Analytics

  /**
   * The Singleton's constructor should always be private to prevent direct
   * construction calls with the `new` operator.
   */
  private constructor() {}

  /**
   * The static method that controls the access to the singleton instance.
   *
   * This implementation let you subclass the Singleton class while keeping
   * just one instance of each subclass around.
   */
  public static getInstance(): Analytics {
    if (!Analytics.instance) {
      Analytics.instance = new Analytics()
    }

    return Analytics.instance
  }

  debugMode: boolean = process.env.NEXT_PUBLIC_APP_ENV === 'prd' ? false : true
  lastSentPageName: string | undefined

  public sendPageView(
    page_name: string,
    page_category: string[],
    page_status: number,
    page_template: string,
    locale?: string,
  ) {
    // Don't send the same page navigation event twice.
    if (this.lastSentPageName === page_name) {
      return
    }

    const udata = window.udata || []
    const data = {
      event_name: 'page navigation',
      page_language: locale?.toUpperCase() ?? 'NL',
      page_name: page_name,
      page_category: page_category,
      page_status: page_status,
      page_template: page_template,
    }
    udata.push(data)
    window.udata = udata
    this.lastSentPageName = page_name

    if (this.debugMode) {
      console.log('Page navigation', data)
    }
  }

  public sendFormSubmitEvent(
    form_name: string,
    form_values1?: number,
    form_values2?: number,
  ) {
    const udata = window.udata || []
    let data: any = {
      event_name: 'form submit',
      form_name: form_name,
    }

    if (form_values1 !== undefined) {
      data.form_values1 = form_values1
    }
    if (form_values2 !== undefined) {
      data.form_values2 = form_values2
    }

    udata.push(data)
    window.udata = udata
    if (this.debugMode) {
      console.log('sendFormSubmitEvent: ', data)
    }
  }

  /// Debounced because the analytics team didn't want every keystroke
  /// to generate an event.
  debouncedSendSiteSearchEvent = debounce((data: any, udata: any) => {
    udata.push(data)
    window.udata = udata
    if (this.debugMode) {
      console.log('sendSiteSearchEvent: ', data)
    }
  }, 2000)

  public sendSiteSearchEvent(
    search_term: string,
    search_results: number,
    active_list_filters: {name: string; category: string}[],
  ) {
    const udata = window.udata || []
    const data = {
      event_name: 'search',
      search_category: 'site',
      search_term: search_term,
      search_results: search_results,
      active_list_filters: active_list_filters,
    }
    this.debouncedSendSiteSearchEvent(data, udata)
  }

  public sendSiteSearchFilterChangeEvent(
    is_removal: boolean,
    search_term: string,
    search_results: number,
    list_filters: {name: string; category: string}[],
    active_list_filters: {name: string; category: string}[],
  ) {
    const udata = window.udata || []
    const data = {
      event_name: is_removal ? 'list filter removal' : 'list filter',
      search_category: 'site',
      search_term: search_term,
      search_results: search_results,
      list_name: 'site search results',
      list_filters: list_filters,
      active_list_filters: active_list_filters,
    }
    udata.push(data)
    window.udata = udata
    if (this.debugMode) {
      console.log('sendSiteSearchFilterChangeEvent: ', data)
    }
  }

  public sendStoreSearchEvent(
    search_term: string,
    search_results: number,
    active_list_filters: {name: string; category: string}[],
  ) {
    const udata = window.udata || []
    const data = {
      event_name: 'search',
      search_category: 'store',
      search_term: search_term,
      search_results: search_results,
      active_list_filters: active_list_filters,
    }
    udata.push(data)
    window.udata = udata
    if (this.debugMode) {
      console.log('sendStoreSearchEvent: ', data)
    }
  }

  public sendStoreListFiltersEvent(
    is_removal: boolean,
    search_term: string,
    search_results: number,
    list_filters: {name: string; category: string}[],
    active_list_filters: {name: string; category: string}[],
  ) {
    const udata = window.udata || []
    const data = {
      event_name: is_removal ? 'list filter removal' : 'list filter',
      search_category: 'store',
      search_term: search_term,
      search_results: search_results,
      list_name: 'store search results',
      list_filters: list_filters,
      active_list_filters: active_list_filters,
    }
    udata.push(data)
    window.udata = udata
    if (this.debugMode) {
      console.log('sendStoreListFiltersEvent: ', data)
    }
  }

  public sendClickStoreDetailEvent(store_name?: string) {
    const udata = window.udata || []
    const data = {
      event_name: 'interaction',
      interaction_name: 'click: store detail',
      interaction_details1: store_name ?? '',
    }
    udata.push(data)
    window.udata = udata
    if (this.debugMode) {
      console.log('sendClickStoreDetailEvent: ', data)
    }
  }

  public sendBlogListFilterEvent(
    is_removal: boolean,
    search_results: number,
    list_filters: {name: string; category: string}[],
    active_list_filters: {name: string; category: string}[],
  ) {
    const udata = window.udata || []
    const data = {
      event_name: is_removal ? 'list filter removal' : 'list filter',
      search_results: search_results,
      list_name: 'content blog search results',
      list_filters: list_filters,
      active_list_filters: active_list_filters,
    }
    udata.push(data)
    window.udata = udata
    if (this.debugMode) {
      console.log('sendBlogListFilterEvent: ', data)
    }
  }
}
