import {useRouter} from 'next/router'
import React, {createContext, useContext, useEffect, useState} from 'react'
import {Segment} from 'util/slugHelpers/segment'

export enum UserType {
  individual = 'Individual',
  professional = 'Professional',
}

type SessionContextType = {
  selectedUserType: UserType | undefined
  isIndividual: () => boolean
}

const SessionContext = createContext<SessionContextType>(
  {} as SessionContextType,
)

export function SessionContextProvider({children}: {children: any}) {
  const router = useRouter()

  // Selected user type state.
  const [selectedUserType, setSelectedUserType] = useState<
    UserType | undefined
  >()

  useEffect(() => {
    const path = router.asPath

    if (
      path.includes(Segment.professional) ||
      path.includes(Segment.professionnel)
    ) {
      setSelectedUserType(UserType.professional)
    } else {
      setSelectedUserType(UserType.individual)
    }
  }, [router.asPath])

  const isIndividual = () => {
    return selectedUserType === UserType.individual
  }

  const value = {
    selectedUserType,
    isIndividual,
  }

  return (
    <SessionContext.Provider value={value}>{children}</SessionContext.Provider>
  )
}

export const useSessionContext = () => {
  return useContext(SessionContext)
}
