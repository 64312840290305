import {TranslatedSlug} from './types'

export interface ContextParams {
  params: {
    slug: string[]
  }
  locale: string
}

export type storyDataType = {
  story: {
    name: string
    id: number
    uuid: string
    content: {
      _uid: string
      content: []
      component: string
    }
    slug: string
    full_slug: string
    default_full_slug: string
    is_startpage: boolean
    lang: string
    path: string
    alternates: []
    translated_slugs: TranslatedSlug[]
    tag_list: string[]
  }
}

// --- Service Point Locator ---

export enum ServiceDeliveryPointType {
  fuel = 'FUEL',
  charge = 'CHARGE',
}

export enum ServiceDeliveryPointAvailability {
  available = 'available',
  unavailable = 'unavailable',
  inUse = 'in_use',
  unknown = 'unknown',
  reserved = 'reserved',
}

export enum ChargingConnectorType {
  cHAdeMO = 'CHADEMO',
  ccs1 = 'COMBO-TYPE1',
  ccs2 = 'COMBO-TYPE2',
  type1 = 'IEC-62196-TYPE1',
  type2 = 'IEC-62196-TYPE2',
  type3 = 'IEC-62196-TYPE3',
  schukoDENL = 'CEE-7-4',
  schukoBEFR = 'CEE-7-5',
  schukoEURO = 'CEE-7-7',
}

export enum ServiceDeliveryPointOperator {
  all = 'ALL',
  dats = 'DATS24',
  other = 'OTHER',
}

export type ServiceDeliveryPointSearchRequestBody = {
  latitude: number
  longitude: number
  searchRadius: number
  filterCriteria: {
    serviceDeliveryPointType: ServiceDeliveryPointType[]
    serviceDeliveryPointAvailability: ServiceDeliveryPointAvailability[]
    chargingConnectorType: ChargingConnectorType[]
    chargingConnectorPowerType: string[]
    chargingConnectorPower?: number
    chargingLocationOperatorName: ServiceDeliveryPointOperator[]
    fuelProductType: string[]
  }
}

export type ServiceDeliveryPointSearchResponse = {
  serviceDeliveryPoints: ServiceDeliveryPoint[]
}

export type ServiceDeliveryPoint = {
  latitude: number
  longitude: number
  address: string
  type: ServiceDeliveryPointType
  chargingLocation?: {
    id: number
    operatorName: string
    availability: ServiceDeliveryPointAvailability
    openingHours: string | null
  }
  fuelStation?: {
    id: number
    name: string
  }
}

export interface DeliveryPointInfoResponse {
  serviceDeliveryPoint: ServiceDeliveryPointObject
}

export interface ServiceDeliveryPointObject {
  type: ServiceDeliveryPointType
  address: string
  street: string
  postalCode: string
  city: string
  countryCode: string
  chargingLocation: ChargingLocation
  fuelStation: FuelStation
}

export interface ChargingLocation {
  id: number
  operatorName: string
  availability: ServiceDeliveryPointAvailability
  openingHours: string
  allowsRemoteStart: boolean
  chargingPoints: ChargingPoint[]
}

export interface ChargingPoint {
  id: number
  number: string
  availability: ServiceDeliveryPointAvailability
  allowsRemoteStart: boolean
  chargingConnector: ChargingConnector
}

export interface ChargingConnector {
  id: number
  connectorType: ChargingConnectorType[]
  hasFixedCable: boolean
  powerType: string
  current: number
  voltage: number
  power: number
  chargingTariff: ChargingTariff
}

export interface ChargingTariff {
  startPrice: number
  currency: string
  vatPercentage: number
  kwhPrice: KwhPrice[]
  hourPrice: HourPrice[]
}

export interface HourPrice {
  limit?: number
  hourPriceValue: number
  unit: string
}

export interface KwhPrice {
  kwhPriceValue: number
  unit: string
}

export interface FuelStation {
  id: number
  name: string
  availability: ServiceDeliveryPointAvailability
  products: Product[]
}

export interface Product {
  code: string
  nameDutch: string
  nameFrench: string
  priceEuro: string
  unit: string
  currency: string
  caloricValue?: string
  officialPriceEuro?: string
}
