import * as React from 'react'
import {SVGProps} from 'react'

const IconDiamondTurnRightInverted = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 21" {...props}>
    <g clipPath="url(#clip0_6249_94015)">
      <path
        d="M9.55273 0.527344C10.1621 -0.0820312 11.1504 -0.0820312 11.7637 0.527344L20.2012 8.96484C20.8105 9.57422 20.8105 10.5625 20.2012 11.1758L11.7637 19.6133C11.1543 20.2227 10.166 20.2227 9.55273 19.6133L1.11523 11.1758C0.505859 10.5664 0.505859 9.57812 1.11523 8.96484L9.55273 0.527344ZM12.9746 5.87891C12.7949 5.69922 12.5254 5.64844 12.2949 5.74219C12.0645 5.83594 11.9082 6.06641 11.9082 6.32031V8.50781H9.4082C8.0293 8.50781 6.9082 9.62891 6.9082 11.0078V12.8828C6.9082 13.4023 7.32617 13.8203 7.8457 13.8203C8.36523 13.8203 8.7832 13.4023 8.7832 12.8828V11.0078C8.7832 10.6641 9.06445 10.3828 9.4082 10.3828H11.9082V12.5703C11.9082 12.8242 12.0605 13.0508 12.2949 13.1484C12.5293 13.2461 12.7988 13.1914 12.9746 13.0117L16.0996 9.88672C16.3418 9.64453 16.3418 9.24609 16.0996 9.00391L12.9746 5.87891Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_6249_94015">
        <rect
          width="20"
          height="20"
          fill="white"
          transform="translate(0.658203 0.0703125)"
        />
      </clipPath>
    </defs>
  </svg>
)
export default IconDiamondTurnRightInverted
