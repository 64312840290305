import {StoryblokComponent} from '@storyblok/react'
import CtaContainer from 'components/Content/Cta'
import {UserType, useSessionContext} from 'context/SessionContextProvider'
import {Analytics} from 'lib/analytics'
import {useRouter} from 'next/router'
import React, {useEffect} from 'react'
import {CtaStoryblok, Page_404Storyblok} from 'types/storyblok-types'
import findAndReplaceString from 'util/findAndReplaceString'
import {Segment} from 'util/slugHelpers/segment'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Page404 = (blok: Page_404Storyblok) => {
  const router = useRouter()
  const sessionContext = useSessionContext()

  useEffect(() => {
    Analytics.getInstance().sendPageView('404', [], 200, 'other', router.locale)
  }, [router.locale])

  const sanitizeData = (data: CtaStoryblok) => {
    const selectedUserType = sessionContext.selectedUserType

    const slug = router.asPath
    const splittedSlug = slug.split('/')
    const language = splittedSlug[0]

    let strData = JSON.stringify(data)

    findAndReplaceString(
      strData,
      'common',
      selectedUserType === UserType.individual
        ? Segment.particulier
        : language === 'nl'
        ? Segment.professional
        : Segment.professionnel,
    )

    return JSON.parse(strData)
  }

  return (
    <>
      <div className="w-full [&>section>div]:max-w-3xl">
        {blok.content
          ? blok.content.map((blok: any) => {
              if (blok.type === 'cta') {
                return (
                  <CtaContainer key={blok._uid} blok={sanitizeData(blok)} />
                )
              } else {
                return <StoryblokComponent key={blok._uid} blok={blok} />
              }
            })
          : null}
      </div>
    </>
  )
}

export default Page404
