import React from 'react'

const plusIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="23"
      viewBox="0 0 22 23"
      fill="none"
    >
      <path
        d="M12.5385 3.31971C12.5385 2.46875 11.851 1.78125 11 1.78125C10.149 1.78125 9.46154 2.46875 9.46154 3.31971V10.2428H2.53846C1.6875 10.2428 1 10.9303 1 11.7812C1 12.6322 1.6875 13.3197 2.53846 13.3197H9.46154V20.2428C9.46154 21.0938 10.149 21.7812 11 21.7812C11.851 21.7812 12.5385 21.0938 12.5385 20.2428V13.3197H19.4615C20.3125 13.3197 21 12.6322 21 11.7812C21 10.9303 20.3125 10.2428 19.4615 10.2428H12.5385V3.31971Z"
        fill="#005E75"
      />
    </svg>
  )
}

export default plusIcon
