import {
  ServiceDeliveryPointObject,
  ServiceDeliveryPointType,
} from 'types/api-types'
import {FuelType} from './segment'

export const generateSDPPath = (
  locale: string,
  user_type: string,
  data: ServiceDeliveryPointObject,
  includeLocale?: boolean,
): string => {
  if (!data) return ''
  const deliveryPointType = data.type.toUpperCase()
  const deliveryPointId =
    data.chargingLocation?.id ?? data.fuelStation?.id ?? ''

  if (deliveryPointType === ServiceDeliveryPointType.fuel) {
    const fuel_type =
      locale === 'nl' ? FuelType.tankstation : FuelType.stationservice
    const name = data.fuelStation?.name.split(' ').join('_')
    return `${includeLocale ? `${locale}/` : ''}${user_type}/sdp/${fuel_type}_${name}_${deliveryPointId}`
  } else {
    const fuel_type =
      locale === 'nl' ? FuelType.laadpunt : FuelType.pointdechargement
    const city = (data.city ?? '').split(' ').join('_').replace('/', '_').trim()
    const street = (data?.street ?? '')
      .split(' ')
      .join('_')
      .replace('/', '_')
      .trim()
    return `${includeLocale ? `${locale}/` : ''}${user_type}/sdp/${fuel_type}_${city}_${street}_${deliveryPointId}`
  }
}
