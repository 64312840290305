// NOTE: Tailwind's break-words class has a bug. See
// https://github.com/tailwindlabs/tailwindcss/discussions/10545
// As a workaround we use [overflow-wrap:anywhere].

import React from 'react'

type TextBlockProps = {
  innerHtml: string
  className?: string
  includeAccordionPadding?: boolean
}
export const TextBlock = ({
  innerHtml,
  className,
  includeAccordionPadding,
}: TextBlockProps) => {
  return (
    <>
      <div
        id="richtext"
        className={`${
          includeAccordionPadding ? 'py-[25px]' : 'pb-0'
        } font-sans font-normal text-dats-s5 [overflow-wrap:anywhere] [&>li]:text-base [&>li]:text-dats-s5 [&>ul]:ml-[34px] [&>ul]:list-image-[url(/images/icon-checkmark.svg)] [&>ul]:space-y-2 ${className} w-full max-w-3xl`}
        dangerouslySetInnerHTML={{__html: innerHtml}}
      />
    </>
  )
}

import {TextStoryblok} from 'types/storyblok-types'
import {renderRichText, storyblokEditable} from '@storyblok/react'

type TextBlockContainerProps = {
  blok: TextStoryblok
  topMargin?: number
}
const TextBlockContainer = ({blok, topMargin}: TextBlockContainerProps) => {
  return (
    <div
      {...storyblokEditable(blok)}
      className={`${topMargin && `mt-${topMargin}`} flex`}
    >
      <TextBlock innerHtml={renderRichText(blok.text)} />
    </div>
  )
}
export default TextBlockContainer
