import * as React from 'react'
import {SVGProps} from 'react'

const IconArrowRotateLeft = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="-4 -3 21 21"
    fill="none"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.91537 4.21319H5.3896C5.90836 4.21319 6.32748 4.63231 6.32748 5.15107C6.32748 5.66984 5.90836 6.08895 5.3896 6.08895H1.63808C1.11931 6.08895 0.700195 5.66984 0.700195 5.15107V1.39955C0.700195 0.880786 1.11931 0.46167 1.63808 0.46167C2.15684 0.46167 2.57596 0.880786 2.57596 1.39955V2.90016L3.09179 2.38433C5.65631 -0.180192 9.81229 -0.180192 12.3768 2.38433C14.9413 4.94884 14.9413 9.10483 12.3768 11.6693C9.81229 14.2339 5.65631 14.2339 3.09179 11.6693C2.72543 11.303 2.72543 10.708 3.09179 10.3417C3.45815 9.9753 4.05312 9.9753 4.41948 10.3417C6.25128 12.1735 9.22025 12.1735 11.0521 10.3417C12.8839 8.50986 12.8839 5.54088 11.0521 3.70908C9.22025 1.87728 6.25128 1.87728 4.41948 3.70908L3.91537 4.21319Z"
      fill="white"
    />
  </svg>
)
export default IconArrowRotateLeft
