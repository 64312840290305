export default function findAndReplaceString(
  obj: any,
  searchString: string,
  replacement: string,
) {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (
        isKeyToReplaceCommon(key) &&
        typeof obj[key] === 'string' &&
        obj[key].includes(searchString)
      ) {
        obj[key] = obj[key].replace(`${searchString}/`, `${replacement}/`) // Replace the string
      } else if (typeof obj[key] === 'object') {
        findAndReplaceString(obj[key], searchString, replacement) // Recurse into nested objects
      }
    }
  }
}

const isKeyToReplaceCommon = (key: string) => {
  return (
    key === 'href' ||
    key === 'cached_url' ||
    key === 'url' ||
    key === 'default_full_slug' ||
    key === 'full_slug' ||
    key === 'path'
  )
}
